import {Container, Row, Col} from "react-bootstrap";
import HeaderAcceso from "../../UI/header/HeaderAcceso";
import {useRef, useState} from "react";
import Slider from "react-slick";
import React,{Component} from "react";
const AccesoMobil = ({accesos}) => {

    const [acceso, setAcceso] = useState(0);
    const slickRef = useRef();

    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false
    };

    const goPrev = () => {
        slickRef.current.slickPrev();
    }

    const goNext = () => {
        slickRef.current.slickNext();
    }

    return <div className={`acceso ${accesos[acceso].bgAlias}`}>
        <HeaderAcceso/>
        <Container className={"text-center"}>
            <Row className={"logo-agencia"}>
                <Slider ref={slickRef} {...settings} beforeChange={(olc, nec) => {
                    setAcceso(nec);
                }}>
                    {accesos.map((item, index) => {
                        return <div key={`image-slide-${index} text-center`} className="item">
                            <a href={item.url} alt={item.title}>
                                <img src={item.image} className={"img-fluid mx-auto"}/>
                            </a>
                        </div>
                    })}
                </Slider>

            </Row>
            <Row className={"description"}>
                <Col>
                    <h2>{accesos[acceso].title}</h2>
                    <div className={"access-selection"}>
                        <div className={"arrow-left"} onClick={goPrev}/>
                        <div className={"world-icon"}/>
                        <div className={"arrow-right"} onClick={goNext}/>
                    </div>
                    <button onClick={() => window.open(accesos[acceso].url, "_blank") }className={"btn btn-access"}><span className={"d-block"}>Acceso al </span> portal
                    </button>
                </Col>
            </Row>
        </Container>
    </div>
}

export default AccesoMobil;