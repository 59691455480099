import * as yup from 'yup';

export const contactHomeSchema = yup.object().shape({
    fullname: yup.string().required("El Nombre es requerido"),
    phone: yup.number().typeError("El Teléfono es inválido").required("El Teléfono es requerido"),
    message: yup.string().required("El Mensaje es requerido").min(0).trim(),
    email: yup.string().email("El Correo Electrónico es inválido").required("El Correo Electrónico es requerido")
});

export const contactSchema = yup.object().shape({
    firstname: yup.string().required("El Nombre es requerido"),
    lastname: yup.string().required("El Apellido es requerido"),
    phone: yup.number().typeError("El Teléfono es inválido").required("El Teléfono es requerido"),
    category: yup.string().required("La Categoría es requerida"),
    notes: yup.string().required("El Mensaje es requerido").min(0).trim(),
    email: yup.string().email("El Correo Electrónico es inválido").required("El Correo Electrónico es requerido")
});

export const empleoSchema = yup.object().shape({
    firstname: yup.string().required("El Nombre es requerido"),
    lastname: yup.string().required("El Apellido es requerido"),
    phone: yup.number().typeError("El Teléfono es inválido").required("El Teléfono es requerido"),
    category: yup.string().required("El área de interés es requerida"),
    notes: yup.string().required("El Comentario es requerido").min(0).trim(),
    email: yup.string().email("El Correo Electrónico es inválido").required("El Correo Electrónico es requerido")
});