import axios from "axios";

const postContact = (params) => {
    debugger;
    return axios.post(`${process.env.REACT_APP_API_URL}/contact`, params).then((response) => {
        return true;
    });
}

const postAgencia = (params) => {
    debugger;
    return axios.post(`${process.env.REACT_APP_API_URL}/quote`, params).then((response) => {
        return true;
    });
}

export {postContact, postAgencia};