import {Col, Container, Row} from "react-bootstrap";
import valueOne from "../../../images/agencia/diferencia-transporte.png";
import valueTwo from "../../../images/agencia/diferencia-agencia.png";
import valueThree from "../../../images/agencia/diferencia-logistica.png";
import bgDiferencia from "../../../images/agencia/bg-hacemos-diferencia.png";
import React,{Component} from "react";
const HacemosDiferencia = () => {
    return <div className={"core-values"} style={{ backgroundImage: `url(${bgDiferencia})`}}>
        <Container className={"py-3 pt-md-5 pb-md-4"}>
            <Row>
                <Col>
                    <h2 className={"text-yellow"}>Nosotros hacemos <span className={"d-block d-lg-inline-block"}>la diferencia</span></h2>
                    <p className={"text-white"}>Cadena de suministro integral.</p>
                </Col>
            </Row>
        </Container>
        <div className={"icons"}>
            <Container className={"py-5"}>
                <Row>
                    <Col className={"mx-auto text-center"} xs={10} md={11} xl={10}>
                        <Row>
                            <Col xs={4} md>
                                <div className={"icon-value"} style={{backgroundImage: `url(${valueOne})`}}/>
                                <h6 className={"text-yellow transparency"}>
                                    <span className={"d-block"}>Transporte </span>
                                    Internacional
                                </h6>
                            </Col>
                            <Col xs className={"d-none d-md-block divisor-vertical-yellow transparency"}/>
                            <Col xs={4} md>
                                <div className={"icon-value"} style={{backgroundImage: `url(${valueTwo})`}}/>
                                <h6 className={"text-yellow transparency"}>
                                    <span className={"d-block"}>Agencia </span>Aduanal
                                </h6>
                            </Col>
                            <Col xs className={"d-none d-md-block divisor-vertical-yellow transparency"}/>
                            <Col xs={4} md>
                                <div className={"icon-value"} style={{backgroundImage: `url(${valueThree})`}}/>
                                <h6 className={"text-yellow transparency"}>
                                    <span className={"d-block"}>Logística </span>
                                    internacional
                                </h6>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
        </div>
    </div>;
}

export default HacemosDiferencia;
