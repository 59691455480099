import {Container, Row, Col} from "react-bootstrap";
import "./Valores.scss";
import Slider from "react-slick";
import {useState} from "react";
import React,{Component} from "react";
const Valores = () => {
    const [slideNumber, setSlideNumber] = useState(0);
    const settings = {
        dots: false,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        vertical: true,
        verticalSwiping: true,
        speed: 500,
        beforeChange: function(currentSlide, nextSlide) {
            setSlideNumber(nextSlide);
        },
        afterChange: function(currentSlide) {
            setSlideNumber(currentSlide);
        }
    };

    return <div className={"valores"}>
        <Container className={"position-relative"}>
            <Row>
                <Col md={10} className={"mx-auto"}>
                    <Row>
                        <Col xs={2} md={3} className={"d-flex flex-column flex-wrap align-items-center position-relative"}>
                            <div className={"transport-icon"}>
                                <div className={`transport slide-${slideNumber}`}/>
                            </div>
                        </Col>
                        <Col xs={9} md className={"pt-4 py-2 py-md-5 my-4"}>
                            <h3>Nuestros Valores</h3>
                            <div className={"valores-slide position-relative"}>
                                <Slider {...settings}>
                                    <div className={"slide text-justify mr-2"}>
                                        <h4>Misión</h4>
                                        <p className={"pb-0 mb-0"}>Generamos valor económico a través del diseño y construcción de modelos de negocio con atención diferenciada a nuestros clientes, con base a sus necesidades operativas y administrativas.</p>
                                        <p className={"pb-0 mb-0"}>Generamos valor diferencial en atención a clientes, mediante nuestro capital humano, impartiendo una correcta capacitación, seguimiento y desarrollo de habilidades que nos permiten brindar un soporte integral de nuestros servicios.</p>
                                    </div>
                                    <div className={"slide text-justify mr-2"}>
                                        <h4>Visión</h4>
                                        <p className={"mb-0 pb-0"}>El enfoque hacia el cumplimiento de nuestra misión solo se compara con la pasión por alcanzar nuestros objetivos estratégicos:</p>
                                        <ul className={"mb-0 pb-1 w-100"}>
                                            <li>Ser el mejor socio comercial y operativo para nuestros clientes.</li>
                                            <li>Ser líderes en nuestro segmento.</li>
                                            <li>Ser el mejor empleador y vecino de las comunidades en las que operamos</li>
                                        </ul>
                                    </div>
                                </Slider>
                            </div>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Container>
    </div>
}

export default Valores;
