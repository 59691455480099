import MainHeader from "../../UI/MainHeader";
import MainImage from '../../../images/clientes/clientes-main.jpg';
import Socios from "./Socios";
import NuestraExperiencia from "./../clientes/NuestraExperiencia";
import SolicitaCotizacion from "./../clientes/SolicitaCotizacion";
import PluginChat from "../../UI/PluginChat";
import Footer from "../../UI/Footer";
import React,{Component} from "react";
const Grupo = () => {
    return(<div>
       
    </div>);
}

export default Grupo;
