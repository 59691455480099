import {Col, Row} from "react-bootstrap";
import opcionMasImg from "./../../../images/transporte/transporte-mas-10.png";
import opcionMenosImg from "./../../../images/transporte/transporte-menos-10.png";

import CotizacionContacto from "../../UI/cotizacion/CotizacionContacto";
import FreightCotizacionMenosDiez from "./FreightCotizacionMenosDiez";
import FreightCotizacionMayorDiez from "./FreightCotizacionMayorDiez";
import {useEffect, useRef, useState} from "react";
import logo from "../../../images/transporte/logo-transport.png";
import React,{Component} from "react";
const FreightCotizaciones = () => {
    const [active, setActive] = useState("menor");
    const [status, setStatus] = useState(false);
    const [height, setHeight] = useState(300);

    const contactRef = useRef();

    useEffect(() => {
        if(contactRef.current){
            setHeight(contactRef.current.getBoundingClientRect().height);
        }
        setStatus(false);
    }, [contactRef, active]);

    return <div>
        <CotizacionContacto type={"transporte"} active={active} setActive={setActive} opcionMasImg={opcionMasImg} opcionMenosImg={opcionMenosImg}>
            <Row className={"py-3 py-md-5"}>
                {
                    status && <Col md={8} className={"mx-auto success-center"} style={{ height: height }}>
                    <Row>
                        <Col className={"text-center"} lg={11}>
                            <Row>
                                <Col xs={6} md={7} className={"mx-auto"}>
                                    <img
                                        src={logo}
                                        alt={"Rodriguez"}
                                        className={"img-fluid mb-4 mb-md-5"}
                                    />
                                </Col>
                            </Row>
                            <h4 className={"mb-4"}>
                                ¡Tu solicitud ha sido enviada con éxito!
                            </h4>
                            <p className={"mx-auto d-block text-center"}>
                                <span className={"d-block"}>Nuestro equipo de atención revisará la
                                información </span>
                                y se comunicará contigo a la
                                brevedad.
                            </p>
                        </Col>
                    </Row>
                    </Col>
                }

                {
                    !status && <Col md={8} className={"mx-auto form-block"} ref={contactRef}>
                        {
                            active === "menor" && <FreightCotizacionMenosDiez status={status} setStatus={setStatus}/>
                        }

                        {
                            active === "mayor" && <FreightCotizacionMayorDiez status={status} setStatus={setStatus}/>
                        }
                    </Col>
                }

            </Row>
        </CotizacionContacto>
    </div>
}

export default FreightCotizaciones;