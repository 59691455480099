import MainHeader from "../../UI/MainHeader";
import MainImage from "../../../images/transporte/transporte-main-bg.jpg";
import PluginChat from "../../UI/PluginChat";
import Footer from "../../UI/Footer";
import Disponibilidad from "./Disponibilidad";
import SolicitaCotizacion from "./SolicitaCotizacion";
import Valores from "./Valores";
import QuienesSomos from "./QuienesSomos";
import React,{Component} from "react";
const InternationalTransport = () => {
    return <div className={"main international-transport"}>
        <MainHeader type={"agencia"} image={MainImage} activeButton={true} buttonSettings={{
            'text': 'Solicita cotización',
            'url': '/international-transport/cotizacion'
        }} header={<h4 className={"mt-2"}>
            <span className={"d-block"}>R&R International </span>
            <span className={"d-block"}>Transport</span>
        </h4>}/>
        <QuienesSomos/>
        <Disponibilidad/>
        <Valores/>
        <SolicitaCotizacion/>
        <PluginChat/>
        <Footer/>
    </div>
}

export default InternationalTransport;
