import {Col, Row} from "react-bootstrap";
import React from 'react';
import CertificacionUno from "./../../../images/inicio/certificaciones/ctpat-logo-active.png";
import CertificacionOEA from "./../../../images/inicio/certificaciones/oea-logo-active.png";
import CertificacionQCB from "./../../../images/inicio/certificaciones/qcb-logo-active.png";
import CertificacionUS from "./../../../images/inicio/certificaciones/us-customs-logo-active.png";

import "./Certificaciones.scss";

const Certificaciones = ({hideText, hover = false}) => {
    const certClass = hover ? 'hover-border cert-item' : 'cert-item';
    return <React.Fragment>
        <div className={"certificaciones"}>
            { !hideText && <div><h2 className={"mt-md-3"}>Certificaciones</h2>
            <p className={"mt-md-3"}>Nuestras certificaciones nacionales e internacionales garantizan que la totalidad de nuestras operaciones cumplen con estrictos procedimientos y estándares en materia de calidad y seguridad de cadena de suministro, alcanzando así los mejores resultados en el control y la coordinación sinérgica de actividades de cumplimiento, comercio exterior y aduanas.</p></div>}
            <Row className={`text-center ${hideText ? 'mt-5 mt-md-2' : 'mt-md-4' }`}>
                <Col className={certClass} xs={hideText ? 6 : 12}  md={3}>
                    <img src={CertificacionOEA} className={'img-fluid'} alt={"OEA"}/>
                </Col>
                <Col className={certClass} xs={hideText ? 6 : 12} md={3}>
                    <img src={CertificacionUno} className={'img-fluid'} alt={"Uno"}/>
                </Col>
                <Col className={certClass} xs={hideText ? 6 : 12} md={3}>
                    <img src={CertificacionUS} className={'img-fluid'} alt={"US"}/>
                </Col>
                <Col className={certClass} xs={hideText ? 6 : 12} md={3}>
                    <img src={CertificacionQCB} className={'img-fluid'} alt={"QBC"}/>
                </Col>
            </Row>
        </div>
    </React.Fragment>
}

export default Certificaciones;
