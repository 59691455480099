import {Col, Container, Row} from "react-bootstrap";
import "./TecnologiaExclusiva.scss";
import TecnologiaExclusivaMobile from "./TecnologiaExclusivaMobile";
import React,{Component} from "react";
const TecnologiaExclusiva = () => {
    return <div id={"tecnologia-exclusiva"}>
        <Container className={"py-2 py-md-3 py-lg-5  position-relative"}>
            <Row className={"d-flex flex-row-reverse"}>
                <Col className={"py-3 px-4 p-md-2"}>
                    <Row>
                        <Col>
                            <h2>Tecnología exclusiva</h2>
                            <p> Tenemos un firme compromiso con tu negocio, el cual manifestamos desarrollando para ti un conjunto de aplicaciones con tecnología innovadora que generan información en tiempo real para aportar a la toma oportuna de decisiones.</p>
                        </Col>
                    </Row>

                    <Row>
                        <Col xs={12} md={5} className={"mx-auto mt-3 mobile-tecnologia"}>
                            <TecnologiaExclusivaMobile/>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Container>
    </div>
}

export default TecnologiaExclusiva;
