import {Col, Container, Row} from "react-bootstrap";
import HeaderAcceso from "../../UI/header/HeaderAcceso";
import "./../Acceso.scss";
import freightImage from "./../../../images/acceso/portal-freight-forwarding.png";
import agenciaImage from "./../../../images/acceso/portal-agencia.png";
import transporteImage from "./../../../images/acceso/portal-transportes.png";
import Footer from "../../UI/Footer";
import React from "react";

const AccesoDesktop = ({accesos}) => {
    return <div className={"acceso"}>
        <HeaderAcceso/>
        <Container className={"position-relative"}>
            <Row>
                <Col lg={10} className={"mx-auto acceso-over pt-4"}>
                    <Row className={"align-items-center justify-content-between"}>
                        {
                            accesos.map((acceso, i, {length}) => {
                                if (length - 1 === i) {
                                    // last one
                                } else {
                                    // not last one
                                }

                                return <React.Fragment>
                                    <Col xs className={"img-zoom"}>
                                        <div className="img-container">
                                            <a href={acceso.url} target={"_blank"}>
                                                <img src={acceso.image} className={"img-fluid mb-3"}/>
                                                <button className={"btn btn-access"}><span className={"d-block"}>Acceso </span> al portal
                                                </button>
                                            </a>
                                        </div>
                                        <div className={"bg-container"}/>
                                    </Col>
                                    { (length - 1 !== i) && <Col className={"d-none d-sm-block divisor-vertical-access"}></Col>}
                                </React.Fragment>
                            })
                        }
                    </Row>
                </Col>
                <Col xs={12} className={"mb-4 mx-lg-2 text-right position-absolute small-letters"}>
                    Acceso total al portal digital es válido para versión digital y versión escritorio.
                </Col>
            </Row>
        </Container>
        <Footer/>
    </div>
}

export default AccesoDesktop;
