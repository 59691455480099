import {useRef, useState} from "react";
import ReactPlayer from 'react-player'
import "./YoutubeEmbed.scss";
import React,{Component} from "react";
const YoutubeEmbed = ({embedId}) => {
    const [state, setState] = useState({
        playing: false
    });

    const [progress, setProgress] = useState(0);
    const ref = useRef();

    const onProgress = (ev) => {
        setProgress(Math.round(ev.played * 100));
    }

    const playYTVideo = () => {
        setState({...state, playing: !state.playing});
    }

    return <div id={'youtube-embed'}>
        <ReactPlayer ref={ref} url={`https://www.youtube.com/watch?v=${embedId}&showinfo=0&autohide=1&controls=0&rel=0`}
                     onProgress={onProgress} config={{
            youtube: {
                height: 200
            },
        }} width='100%'
                     height='230px' {...state}/>
        <div className={"yt-controls"}>
            <div className={`yt-play ${state.playing ? 'pause' : 'play'}`} onClick={() => playYTVideo()}/>
            <div className={"yt-progress"}>
                <div className={"yt-progress-bar"} style={{width: `${progress > 0 ? progress+2 : 0}%`}}/>
            </div>
        </div>
    </div>;
}

export default YoutubeEmbed;