import {Col, Container, Row} from "react-bootstrap";

import "./Nosotros.scss";
import nosotrosMain from '../../images/nosotros/nosotros-main.png';
import rigoRodriguez from '../../images/nosotros/rigordgz.png';
import tijuana from '../../images/nosotros/tijuana.png';

import usClients from '../../images/nosotros/nosotros-clientes.png';
import usGroup from '../../images/nosotros/nosotros-grupo.png';
import usHistory from '../../images/nosotros/nosotros-historia.png';
import nosotrosTestimonial from '../../images/nosotros/rigorr.jpg';
import nosotrosTestimonialMobile from '../../images/nosotros/testmonial-rigo-circle.png';

import MainHeader from "../UI/MainHeader";
import PluginChat from "../UI/PluginChat";
import React, {useState, useRef} from 'react';
import {UseViewPort} from "../../hooks/useViewPort";
import Footer from "../UI/Footer";
import QuienesSomos from "./grupo/QuienesSomos";
import CoreValues from "./nosotros/CoreValues";

const Nosotros = () => {
    const isDesktop = UseViewPort() >= 768;

    const slides = [
        {
            image: <img src={rigoRodriguez} alt={"Rigoberto Rodriguez"}  className={"w-100"}/>,
            description: {
                paragraph: "<strong>La integridad, el Compromiso y el Respeto</strong>, son los valores que durante generaciones hemos conservado en nuestra empresa, siendo estos esenciales para forjar relaciones perdurables con nuestros clientes.",
                name: "Rigoberto Rodríguez / Director General"
            }
        },{
            image: <img src={rigoRodriguez} alt={"Rigoberto Rodriguez"}  className={"w-100"}/>,
            description: {
                paragraph: "<strong>Lorem Ipsum</strong>, Lorem Ipsumn Lorem Ipsumn Lorem Ipsumn Lorem Ipsumn",
                name: "Juan Rodríguez / Director General"
            }
        },{
            image: <img src={rigoRodriguez} alt={"Rigoberto Rodriguez"}  className={"w-100"}/>,
            description: {
                paragraph: "<strong>Lorem Ipsum</strong>, Lorem Ipsumn Lorem Ipsumn Lorem Ipsumn Lorem Ipsumn",
                name: "Mario Rodríguez / Director General"
            }
        },{
            image: <img src={rigoRodriguez} alt={"Rigoberto Rodriguez"}  className={"w-100"}/>,
            description: {
                paragraph: "<strong>Lorem Ipsum</strong>, Lorem Ipsumn Lorem Ipsumn Lorem Ipsumn Lorem Ipsumn",
                name: "José Rodríguez / Director General"
            }
        }
    ];

    const [description, setDescription] = useState(slides[0].description);

    const  slickRef = useRef();
    const settings = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
    };

    return <div className={"nosotros"}>
        <MainHeader image={nosotrosMain}/>
        <Container fluid className={"main px-0"}>
            <QuienesSomos/>
            <Container className={"py-3 pb-lg-5"}>
                <Row>
                    <Col>
                        <p className={"text-center mt-2"}>
                            <strong>Más de 80 años evolucionando la calidad en el comercio exterior.</strong>
                        </p>
                    </Col>
                </Row>
                <Row className={`${isDesktop ? 'mb-4 my-md-2' : ''} social-networks hover-color text-center`}>
                    <Col xs={12}>
                        <ul className={"mt-0 pb-1"}>
                            <li><a target="_blank" href={`${process.env.REACT_APP_LINK_LINKEDIN}`} title="LinkedIn" rel="nofollow"
                                   className={"linkedin gray-scale"}>LinkedIn</a></li>
                            <li><a target="_blank"  href={`${process.env.REACT_APP_LINK_FB}`} title="Facebook" rel="nofollow"
                                   className={"facebook gray-scale"}>Facebook</a></li>
                            <li><a target="_blank"  href={`${process.env.REACT_APP_LINK_YOUTUBE}`} title="YouTube" rel="nofollow"
                                   className={"youtube gray-scale"}>YouTube</a></li>
                            <li><a target="_blank"  href={`${process.env.REACT_APP_LINK_TWITTER}`} title="Twitter" rel="nofollow"
                                   className={"twitter gray-scale"}>Twitter</a></li>

                        </ul>
                    </Col>
                </Row>
            </Container>

            <div className={"testimonials py-3"}>
                <Container lg={8} md={11}>
                    { isDesktop && <Row className={"py-2 px-4"}>
                        <Col className={"m-auto"}>
                            <Row className={"justify-content-center justify-content-md-between d-flex flex-sm-row-reverse py-3 py-md-5"}>
                                <Col xs={8} md className={"pb-lg-0 px-lg-5"}>
                                    <img src={nosotrosTestimonial} className={"img-fluid"}/>
                                </Col>
                                <Col md={6} className={"testimonial-text"}>
                                    <div>
                                        <p dangerouslySetInnerHTML={{ __html: `"${description.paragraph}"` }}/>
                                        {/*<p className={"mb-0"}><strong>{description.name}</strong></p>*/}
                                        <p><strong>Grupo Logístico Rodríguez</strong></p>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </Row> }

                    { !isDesktop && <Row className={"px-4 py-2 col-10 mx-auto"}>
                        <p dangerouslySetInnerHTML={{ __html: `"${description.paragraph}"` }}/>
                        {/*<p className={"mb-0 text-center"}><strong>{description.name}</strong></p>*/}
                        <p className={"text-center"}><strong>Grupo Logístico Rodríguez</strong></p>

                        <Row>
                            <Col xs={7} sm={6} className={"mx-auto py-3"}>
                                <img src={nosotrosTestimonialMobile} className={"img-fluid"}/>
                            </Col>
                        </Row>
                    </Row>}
                </Container>
            </div>
            <CoreValues/>
            <Container className={"pt-5 py-md-5 about-more"}>
                <Row className={"text-center justify-content-around"}>
                    <Col md lg={3} className={"img-zoom mb-4 mb-md-0"}>
                        <div className={"img-container"}>
                            <a href={"/historia"} title={"Historia"}>
                                <img src={usHistory} className={"img-fluid"}/>
                            </a>
                        </div>
                        <h4>Historia</h4>
                    </Col>
                    <Col md lg={3} className={"img-zoom mb-4 mb-md-0"}>
                        <div className={"img-container"}>
                            <a href={"/grupo"} title={"Grupo"}>
                                <img src={usGroup} className={"img-fluid"}/>
                            </a>
                        </div>
                        <h4>Grupo</h4>
                    </Col>
                    <Col md lg={3} className={"img-zoom"}>
                        <div className={"img-container"}>
                            <a href={"/clientes"} title={"Clientes"}>
                                <img src={usClients} className={"img-fluid"}/>
                            </a>
                        </div>
                        <h4>Clientes</h4>
                    </Col>
                </Row>
            </Container>
            <Container className={"py-3 py-md-5 offices"}>
                <Row className={"mb-4"}>
                    <Col>
                        <h2>Oficinas en México y Estados Unidos.</h2>
                        <p><strong>En Grupo Logístico Rodríguez </strong>estamos disponibles las 24 horas de los 7 días de la semana para darte el servicio aduanal que te mereces. Nuestra presencia en México y Estados Unidos, nos permite atenderte de acuerdo con tus necesidades.</p>
                    </Col>
                </Row>


                <Row className={"my-2 text-center"}>
                    <Col xs={12} sm={6} md={4} className={"mx-auto"}>
                        <img src={tijuana} alt={"Tijuana"} className={"img-fluid mb-3 col-md-12 col-8 col-md-12"}/>
                        <h3 className={"mx-auto d-block text-center"}>Tijuana</h3>
                        <p className={"text-smaller text-center"}>
                            <span className={"d-block"}>Blvd. 3ra Oeste No. 17500-C</span>
                            Fracc. Garita de Otay, C.P. 22430 Tijuana, B.C.</p>
                        <button className={"btn-filled btn-small mx-auto d-block mb-5 mb-md-0"} onClick={() => {
                            window.open('https://goo.gl/maps/DP4ydiszecPc4Goe8', '_blank');
                        }}>Ver Mapa</button>
                    </Col>
                    <Col xs={12} sm={6} md={4}  className={"mx-auto"}>
                        <img src={tijuana} alt={"Tijuana"} className={"img-fluid mb-3 col-8 col-md-12"}/>
                        <h3 className={"mx-auto d-block text-center"}>Ensenada</h3>
                        <p className={"text-smaller text-center"}>
                            <span className={"d-block"}>Calle segunda #300 entre Ruiz y Álvaro Obregón,</span>
                            local 8, Zona Centro C.P. 22800, Ensenada, B.C.
                        </p>
                        <button className={"btn-filled btn-small mx-auto d-block mb-5 mb-md-0"} onClick={() => {
                            window.open('https://goo.gl/maps/v9cTEFXPpThrC1rM6', '_blank');
                        }}>Ver Mapa</button>
                    </Col>
                    <Col xs={12} sm={6} md={4}  className={"mx-auto"}>
                        <img src={tijuana} alt={"Tijuana"} className={"img-fluid mb-3 col-8 col-md-12"}/>
                        <h3 className={"mx-auto d-block text-center"}>San Diego</h3>
                        <p className={"text-smaller text-center"}><span className={"d-block"}>2450 Siempre Viva Ct.</span>
                            San Diego, <span className={"d-block d-lg-inline-block"}>CA. 92154</span></p>
                        <button className={"btn-filled btn-small mx-auto d-block mb-5 mb-md-0"} onClick={() => {
                            window.open('https://goo.gl/maps/mA88y8YAgBCSnoPB6', '_blank');
                        }}>Ver Mapa</button>
                    </Col>
                </Row>
            </Container>
            <PluginChat/>
        </Container>
        <Footer/>
    </div>
}

export default Nosotros;
