import {Col, Container, Row} from "react-bootstrap";
import "./../agencia/Fortalezas.scss";
import React,{Component} from "react";
const Disponibilidad = () => {
    return <div className={"bg-whitesmoke"}><Container className={"py-4 py-md-5 agencia-fortalezas"}>
        <Row>
            <Col md={11} lg={9} className={"mx-auto"}>
                <h2 className={"mb-1 mb-md-4"}>Máxima disponibilidad es:</h2>

                <ul className={"check-mark-list"}>
                    <li><span>Personal capacitado y certificado</span></li>
                    <li><span>Monitoreo GPS las 24 Hrs del día</span></li>
                    <li><span>Sistemas de trazabilidad de rutas mediante Geo Cercas</span></li>
                    <li><span>Estándares de seguridad CTPAT y OEA</span></li>
                    <li><span>Transporte en caja seca, plataforma y otras unidades</span></li>
                    <li><span>Yarda en Tijuana y San Diego para transbordos</span></li>
                    <li><span>Segurdiad y supervisión en Yarda</span></li>
                    <li><span>Más de 25 equipos de transporte y carga</span></li>
                </ul>
            </Col>
        </Row>
    </Container>
    </div>
}

export default Disponibilidad;
