import axios from "axios";

const formatExchange = (number) => {
    return (number.toLocaleString('en-US', { minimumFractionDigits: 2 }));//en-US
}

const formatDate = (currentDate) => {
    return (new Date(currentDate).toLocaleDateString("es-ES", { month: 'long', day: 'numeric' }));
}

const getYear = (currentDate) => {
    return (new Date(currentDate).getFullYear());
}

const getExchange = () => {
    //return axios.get(`${process.env.REACT_APP_API_URL}/exchange`).then((response) => {
    return axios.get(`${"https://wsapi.glrodriguez.mx"}/exchange`).then((response) => {
        let result = response.data[0];
        return {
            rate_1: new Number(formatExchange(result.rate_1)).toFixed(4),
            rate_2: new Number(formatExchange(result.rate_2)).toFixed(4),
            updated_date_1: formatDate(result.date_1)+' del '+getYear(result.date_1),
            updated_date_2: formatDate(result.date_2)+' del '+getYear(result.date_2),
        }
    }).catch(() => {
        let defaultPrice = formatExchange(0);
        return {
            rate_1: 0,
            rate_2: 0,
            updated_date_1: formatDate(''),
            updated_date_2: formatDate('')
        }
    });
}


export {getExchange};