import {useState} from "react";
import theme from "../../../theme";
import {Button, Col, Container, Row} from "react-bootstrap";
import {Grid} from "@mui/material";
import CustomForm from "../../UI/CustomForm";
import {ThemeProvider} from "@emotion/react";
import {AgencyQuoteMoreThanTenSchema, AgencyQuoteSchema} from "../../../validations/AgencyValidation";
import {postAgencia} from "./../../../utils/forms";
import React,{Component} from "react";
const FreightCotizacionMayorDiez = ({setStatus}) => {
    const [loading, setLoading] = useState(false);
    const [errorMsg, setErrorMsg] = useState("");

    const [errors, setErrors] = useState({});
    const [fieldsValues, setFieldsValues] = useState({});

    const fields = [
        {
            type: "text",
            name: "firstname",
            required: true,
            rowSize: 7,
            placeholder: "Nombre",
        },
        {
            type: "text",
            name: "lastname",
            required: true,
            rowSize: 5,
            placeholder: "Apellido",
        },
        {
            type: "text",
            name: "company",
            required: true,
            rowSize: 7,
            placeholder: "Empresa",
        },
        {
            type: "text",
            name: "position",
            required: true,
            rowSize: 5,
            placeholder: "Puesto",
        },
        {
            type: "email",
            name: "email",
            required: true,
            rowSize: 12,
            placeholder: "Correo Electrónico",
        },
        {
            type: "phone",
            name: "phone_office",
            required: true,
            rowSize: 5,
            placeholder: "Teléfono de Oficina",
        },
        {
            type: "number",
            name: "phone_ext",
            required: false,
            rowSize: 2,
            placeholder: "Extensión",
        },
        {
            type: "phone",
            name: "phone_personal",
            required: false,
            rowSize: 5,
            placeholder: "Teléfono Particular",
        },{
            type: "textarea",
            rows: 4,
            name: "comments",
            required: true,
            rowSize: 12,
            placeholder: "Comentario"
        }
    ];

    const validateData = async() => {
        try {
            await AgencyQuoteMoreThanTenSchema.validate(fieldsValues, { abortEarly: false });
            return true;
        } catch (e) {
            let messages = e.errors;

            if(!messages.length) return true;
            let populateErrors = [];

            fields.map((field) => {
                const match = messages.find((element, index) => {
                    let containText = field.validator ?? field.placeholder;
                    if (element.includes(containText)) {
                        return messages[index];
                    }
                    return false;
                });

                if(typeof match === 'string'){
                    populateErrors[field.name] = match;
                }
                return true;
            });
            setErrors(populateErrors);
            return false;
        }
    }

    const sendForm = () => {
        setLoading(true);
        return validateData().then( async (response) => {
            if(response){
                fieldsValues.formname = 'Cotizacion Transporte';
                fieldsValues.formtitle = 'Mas de 10 movimientos';

                await postAgencia(fieldsValues).then((result) => {
                    setStatus(true);
                }).catch( () => {
                    setErrorMsg('Hubo un error, favor intente nuevamente.');
                }).finally(() => {
                    setLoading(false);
                })
            }else{
                setLoading(false);
            }

        });
    };

    return <ThemeProvider theme={theme}>
        <Container>
            <Row>
                <Col>
                    <h3 className={"font-weight-light"}>Mayor de <span className={"font-weight-bold"}>10 operaciones</span> al mes</h3>
                </Col>
            </Row>
            <Grid container spacing={2} align={"center"} >
                <CustomForm fieldsValues={fieldsValues} fields={fields}  setFieldsValues={setFieldsValues} errors={errors} setErrors={setErrors} formError={errorMsg} setFormError={setErrorMsg}/>
                <Grid item xs={12} align={"left"}>
                    <Button key="contact-btn" className={"btn btn-enviar mb-4 mb-md-0 border-none"} onClick={sendForm}>
                        {loading ? 'Enviando...' : 'Enviar solicitud'}
                    </Button>
                </Grid>
            </Grid>
        </Container>
    </ThemeProvider>
}

export default FreightCotizacionMayorDiez;