import MainHeader from "../../UI/MainHeader";
import MainImage from '../../../images/grupo/grupo-main.jpg';
import OfertaSoluciones from "./OfertaSoluciones";
import Almacen from "./Almacen";
import Operaciones from "./Operaciones";
import Sucursales from "./Sucursales";
import TecnologiaExclusiva from "./TecnologiaExclusiva";
import PluginChat from "../../UI/PluginChat";
import Footer from "../../UI/Footer";
import Transporte from "./Trasporte";
import Afiliaciones from "./Afiliaciones";
import React,{Component} from "react";
const Grupo = () => {
    return(<div>
        <MainHeader image={MainImage} activeButton={true} type={"grupo"}/>
        <OfertaSoluciones/>
        <Sucursales/>
        <TecnologiaExclusiva/>
        <Operaciones/>
        <Almacen/>
        <Transporte/>
        <Afiliaciones/>
        <PluginChat/>
        <Footer/>
    </div>);
}

export default Grupo;
