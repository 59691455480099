import {Col, Container, Dropdown, Nav, Navbar, Row, Tab, Tabs} from "react-bootstrap";
import "./HeaderContacto.scss";
import React,{Component} from "react";
const HeaderContacto = () => {
    return <Row className={"px-3 px-md-0 mt-md-4"}>
    <Col xs={12} md  className={"menu-list header-contacto"}>
            <div>
                <h6>Grupo Logístico Rodríguez</h6>
                <ul>
                    <li>
                        <h6>Tijuana</h6>
                        <div className={"header-contact"}>
                            <ul className={"mb-0"}>
                                <li className={"phone v-middle"}>
                                    <span className={"d-inline-block"}>Tel: +52 (664) 683 1022 / 23</span>
                                </li>
                                <li className={"email v-unset"}>
                                    <span className={"d-inline-block"}>
                                        <a href={"mailto:ventas@glrodriguez.mx"} target={"_blank"} className={"bold d-block"}>ventas@glrodriguez.mx</a>
                                        <a href={"mailto:sales@glrodriguez.mx"} target={"_blank"} className={"bold d-block"}>sales@glrodriguez.mx</a>
                                    </span>
                                </li>
                                <li className={"location v-top"}>
                                    <span className={"d-inline-block"}>
                                        Blvd. 3ra Oeste No. 17500-C<br/>
                                        Fracc. Garita de Otay,<br/>
                                    C.P: 22430 Tijuana, B.C.</span>
                                </li>
                            </ul>
                        </div>
                    </li>
                    <li>
                        <h6>Ensenada</h6>
                        <div className={"header-contact"}>
                            <ul>
                                <li className={"phone v-unset"}>
                                    <span className={"d-inline-block"}>
                                        Tel: +52 (646) 156 5392<br/>Tel: +52 (646) 175 7206
                                    </span>
                                </li>
                                <li className={"email v-unset"}>
                                    <span className={"d-inline-block"}>
                                        <a href={"mailto:ventas@glrodriguez.mx"} target={"_blank"} className={"bold d-block"}>ventas@glrodriguez.mx</a>
                                        <a href={"mailto:sales@glrodriguez.mx"} target={"_blank"} className={"bold d-block"}>sales@glrodriguez.mx</a>
                                    </span>
                                </li>
                                <li className={"location v-top"}>
                                    <span className={"d-inline-block"}>
                                        Calle segunda #300 entre Ruiz y Álvaro Obregón<br/>
                                        local 8, Zona Centro<br/>
                                        C.P. 22800, Ensenada, B.C.
                                    </span>
                                </li>
                            </ul>



                        </div>
                    </li>
                </ul>
            </div>
        </Col>
        <Col xs={12} md className={"menu-list"}>
            <div>
                <h6>R&R International Transport</h6>

                <ul>
                    <li>
                        <h6>Tijuana</h6>
                        <div className={"header-contact"}>
                            <ul className={"mb-0"}>
                                <li className={"phone v-middle"}>
                                    <span className={"d-inline-block"}>Tel: +52 (664) 683 1022 / 23</span>
                                </li>
                                <li className={"email v-middle"}>
                                    <span className={"d-inline-block"}>
                                        <a href={"mailto:transporte@glrodriguez.mx"} target={"_blank"} className={"bold d-block"}>transporte@glrodriguez.mx</a>
                                    </span>
                                </li>
                                <li className={"location v-top"}>
                                    <span className={"d-inline-block"}>
                                        Blvd. 3ra Oeste No. 17500-C<br/>
                                        Fracc. Garita de Otay,<br/>
                                    C.P: 22430 Tijuana, B.C.</span>
                                </li>
                            </ul>
                        </div>
                    </li>
                    <li>
                        <h6>San Diego</h6>
                        <div className={"header-contact"}>
                            <ul className={"mb-0"}>
                                <li className={"phone v-middle"}>
                                    <span className={"d-inline-block"}>Tel: +1 (619) 661 0027</span>
                                </li>
                                <li className={"email v-middle"}>
                                    <span className={"d-inline-block"}>
                                        <a href={"mailto:transportation@glrodriguez.mx"} target={"_blank"} className={"bold d-block"}>transportation@glrodriguez.mx</a>
                                    </span>
                                </li>
                                <li className={"location v-top"}>
                                    <span className={"d-inline-block"}>
                                        2450 Siempre Viva Ct.<br/>
                                        San Diego, CA. 92154.
                                    </span>
                                </li>
                            </ul>
                        </div>
                    </li>
                </ul>
            </div>
        </Col>
        <Col xs={12} md  className={"menu-list"}>
            <div>
                <h6>R&R International Freight
                    <span className={"d-block"}>Forwarding</span></h6>
                <ul>
                    <li>
                        <h6>San Diego</h6>
                        <div className={"header-contact"}>
                            <ul className={"mb-0"}>
                                <li className={"phone v-unset"}>
                                    <span className={"d-inline-block"}>
                                        Tel: +1 (619) 661 0027<br/>
                                        Fax: +1 (619) 661 0044
                                    </span>
                                </li>
                                <li className={"email v-unset"}>
                                    <span className={"d-inline-block"}>
                                        <a href={"mailto:almacen@glrodriguez.mx"} target={"_blank"} className={"bold d-block"}>almacen@glrodriguez.mx</a>
                                        <a href={"mailto:warehouse@glrodriguez.mx"} target={"_blank"} className={"bold d-block"}>warehouse@glrodriguez.mx</a>
                                    </span>
                                </li>
                                <li className={"location v-top"}>
                                    <span className={"d-inline-block"}>
                                        2450 Siempre Viva Ct.<br/>
                                        San Diego, CA. 92154.
                                    </span>
                                </li>
                            </ul>
                        </div>
                    </li>
                </ul>
            </div>
        </Col>
    </Row>
}

export default HeaderContacto;