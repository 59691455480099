import Header from "../../UI/Header";
import PluginChat from "../../UI/PluginChat";
import Footer from "../../UI/Footer";
import {Col, Container, Row} from "react-bootstrap";
import {UseViewPort} from "../../../hooks/useViewPort";
import opcionMenosImg from "./../../../images/agencia/agenca-mas-10.png";
import opcionMasImg from "./../../../images/agencia/agencia-menos-10.png";


import CotizacionContacto from "../../UI/cotizacion/CotizacionContacto";
import AgenciaCotizacionMenosDiez from "./AgenciaCotizacionMenosDiez";
import AgenciaCotizacionMayorDiez from "./AgenciaCotizacionMayorDiez";
import {useEffect, useRef, useState} from "react";
import logo from "../../../images/contacto/rodriguez.png";
import SuccessMessage from "../../UI/SuccessMessage";
import React,{Component} from "react";
const AgenciaCotizaciones = () => {
    const [active, setActive] = useState("menor");
    const [status, setStatus] = useState(false);
    const [height, setHeight] = useState(300);

    const contactRef = useRef();

    useEffect(() => {
        if(contactRef.current){
            setHeight(contactRef.current.getBoundingClientRect().height);
        }
        setStatus(false);
    }, [contactRef, active]);

    return <div>
        <CotizacionContacto type={"agencia aduanal"} active={active} setActive={setActive} opcionMasImg={opcionMasImg} opcionMenosImg={opcionMenosImg}>
            <Row className={"py-3 py-md-5"}>
                {
                    status && <Col md={8} className={"mx-auto success-center"} style={{ height: height }}>
                    <Row>
                        <Col className={"text-center"} lg={11}>
                            <SuccessMessage logo={logo} alt={"Cotización Agencia Aduanal Rodriguez"}/>
                        </Col>
                    </Row>
                    </Col>
                }

                {
                    !status && <Col xs={12} sm={11} lg={9} xl={8} className={"mx-auto form-block"} ref={contactRef}>
                        {
                            active === "menor" && <AgenciaCotizacionMenosDiez  status={status} setStatus={setStatus}/>
                        }

                        {
                            active === "mayor" && <AgenciaCotizacionMayorDiez  status={status} setStatus={setStatus}/>
                        }
                    </Col>
                }

            </Row>
        </CotizacionContacto>
    </div>
}

export default AgenciaCotizaciones;
