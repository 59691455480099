import MainHeader from "../../UI/MainHeader";
import MainImage from '../../../images/agencia/main-bg.jpg';
import MobileImage from '../../../images/agencia/bg-mobile-agencia.png';

import PluginChat from "../../UI/PluginChat";
import Footer from "../../UI/Footer";
import QuienesSomos from "./QuienesSomos";
import Fortalezas from "./Fortalezas";
import HacemosDiferencia from "./HacemosDiferencia";
import SolicitaCotizacion from "./SolicitaCotizacion";
import TuMejorEleccion from "./TuMejorEleccion";
import {UseViewPort} from "../../../hooks/useViewPort";
import "./Agencia.scss";
import PlataformaTecnologica from "./PlataformaTecnologica";
import React,{Component} from "react";
const Agencia = () => {
    const isMobile = UseViewPort() < 1045;

    return(<div className={"main agencia"}>
        <MainHeader type={"agencia"} image={isMobile ? MobileImage : MainImage} activeButton={true} buttonSettings={{
            'text': 'Solicita asesoría',
            'url': '/agencia/cotizacion'
        }} header={<h4 className={"mt-2"}>
            <span className={"d-block"}>Agencia Aduanal </span>
            <span className={"d-block"}>Rodríguez</span>
        </h4>}/>
        <QuienesSomos/>
        <Fortalezas/>
        <HacemosDiferencia/>
        <PlataformaTecnologica/>
        <TuMejorEleccion/>
        <SolicitaCotizacion/>
        <PluginChat/>
        <Footer/>
    </div>);
}

export default Agencia;
