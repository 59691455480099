import {Col, Container, Row} from "react-bootstrap";
import "./QuienesSomos.scss";
import bgServicios from "./../../../images/agencia/bg-servicios.png";
import bgSolucion from "./../../../images/agencia/bg-solucion.png";
import bgExperiencia from "./../../../images/agencia/bg-experiencia.png";
import bgProfesionalismo from "./../../../images/agencia/bg-profesionalismo.png";

import {useEffect, useRef, useState} from "react";
import FlipCard from "../../UI/FlipCard";
import React,{Component} from "react";
function debounce(fn, ms) {
    let timer
    return _ => {
        clearTimeout(timer)
        timer = setTimeout(_ => {
            timer = null
            fn.apply(this, arguments)
        }, ms)
    };
}

const QuienesSomos = () => {

    const servicioRef = useRef();
    const [height, setHeight] = useState(100);

    useEffect(() => {
        const debouncedHandleResize = debounce(function handleResize() {
            setHeight(servicioRef.current.offsetWidth - 10);
        }, 800)

        window.addEventListener('resize', debouncedHandleResize)

        return _ => {
            window.removeEventListener('resize', debouncedHandleResize)

        }
    });

    useEffect(() => {
        setHeight(servicioRef.current.offsetWidth - 10);
    }, [servicioRef]);

    return <div className={"bg-light-gray"}>
        <Container className={"py-4 pt-md-5 pb-3 pb-lg-4 text-justify"}>
            <Row>
                <Col>
                    <h2>
                        ¿Quiénes somos?
                    </h2>
                    <p>
                        Solución en servicios aduanales conformado por un equipo de profesionales que nos permite
                        brindar atención especializada a cada proyecto con el propósito de agilizar el despacho de
                        mercancías a través de herramientas tecnológicas.
                    </p>
                </Col>
            </Row>
            <Row className={"agencia-servicios"}>
                <Col xs={12} md={11} xl={12} className={"mx-auto"}>
                    <div className={"grid"}>
                        <div className={"servicio"} style={{height: height}} ref={servicioRef}>
                            <FlipCard front={<div className={"servicio-front"} style={{ backgroundImage: `url(${bgServicios})` }}>
                                <div className={"icon-servicios"}/>
                                <h5>Servicios</h5>
                            </div>} back={<div className={"servicio-hover"}>
                                <div className={"half-top"}>
                                    <div className={"icon-servicios"}/>
                                    <h5>Servicios</h5>
                                </div>
                                <div className={"half-bottom"}>
                                    <p> Agencia Aduanal Rodríguez ha
                                        servido a la comunidad industrial y
                                        comercial desde 1940</p>
                                </div>
                            </div>}/>
                        </div>
                        <div className={"servicio"} style={{height: height}}>
                            <FlipCard front={<div className={"servicio-front"} style={{ backgroundImage: `url(${bgProfesionalismo})` }}>
                                <div className={"icon-profesionalismo"}/>
                                <h5>Profesionalismo</h5>
                            </div>} back={<div className={"servicio-hover"}>
                                <div className={"half-top"}>
                                    <div className={"icon-profesionalismo"}/>
                                    <h5>Profesionalismo</h5>
                                </div>
                                <div className={"half-bottom"}>
                                    <p className={"mb-0"}> Nuestro servicio nos diferencia de nuestros competidores, siendo una agencia aduanal
                                        reconocida.</p>
                                    <p>
                                        Creadores de una solución única de negocio para el incremento sostenido del desempeño de
                                        nuestros clientes.</p>
                                </div>
                            </div>}/>
                        </div>
                        <div className={"servicio"} style={{height: height}}>
                            <FlipCard front={<div className={"servicio-front"} style={{ backgroundImage: `url(${bgExperiencia})` }}>
                                <div className={"icon-experiencia"}/>
                                <h5>
                                    <span className={"d-block"}>Experiencia </span>
                                    y especialización
                                </h5>
                            </div>} back={<div className={"servicio-hover"}>
                                <div className={"half-top"}>
                                    <div className={"icon-experiencia"}/>
                                    <h5>
                                        <span className={"d-block"}>Experiencia </span>
                                        y especialización
                                    </h5>
                                </div>
                                <div className={"half-bottom mt-4"}>
                                    <p>Personal con amplia experiencia, especializado en materia aduanal y logística, logrando una satisfacción al cliente.</p>
                                </div>
                            </div>}/>
                        </div>
                        <div className={"servicio"} style={{height: height}}>
                            <FlipCard front={<div className={"servicio-front"} style={{ backgroundImage: `url(${bgSolucion})` }}>
                                <div className={"icon-solucion"}/>
                                <h5>
                                    <span className={"d-block"}>Solución y </span>
                                    logística integral
                                </h5>
                            </div>} back={<div className={"servicio-hover"}>
                                <div className={"half-top"}>
                                    <div className={"icon-solucion"}/>
                                    <h5>
                                        <span className={"d-block"}>Solución y </span>
                                        logística integral
                                    </h5>
                                </div>
                                <div className={"half-bottom mt-4"}>
                                    <p>Analizamos las necesidades operativas de cada cliente para brindar asesoría y soluciones estratégicas.</p>
                                </div>
                            </div>}/>
                        </div>
                    </div>
                </Col>

            </Row>
        </Container>
    </div>
}

export default QuienesSomos;
