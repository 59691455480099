import "./Timeline.scss";
import Logo1940 from '../../../images/historia/logo-1940.png';
import Logo1985 from '../../../images/historia/logo-1985.png';
import Logo2002 from '../../../images/historia/logo-2002.png';
import Logo1960 from '../../../images/historia/logo-1960.png';
import Logo1994 from '../../../images/historia/logo-1994.png';
import Logo2020 from '../../../images/historia/logo-2020.png';
import React,{Component} from "react";
const Timeline = () => {
    return (<section id="conference-timeline">
        <div className="timeline-start text-center">
            <h2 className={"d-inline-block d-md-block"}>Historia</h2>
            <p className={"d-inline-block d-md-block"}><span className={"d-inline-block d-md-none"}>&nbsp;/&nbsp;</span>Línea del tiempo</p>
            <div className="meta-date"/>
        </div>
        <div className="conference-center-line"/>

        <div className="conference-timeline-content">
            <div className="timeline-article">
                <div className="content-left-container">
                    <div className="content-left"  data-aos="fade-right">
                        <div
                            className="article-number">1940</div>
                        <p><span className="d-block">Se obtiene la primer patente </span>
                            <span className="d-block">No. 95 de agente aduanal de </span>
                            Grupo Logístico Rodríguez</p>
                    </div>
                </div>
                <div className="content-right-container" data-aos="fade-left">
                    <div className="content-right timeline-logo text-left">
                        <img src={Logo1940} className={"img-fluid"}/>
                    </div>
                </div>
                <div className="meta-date"/>
            </div>
            <div className="timeline-article">
                <div className="content-left-container"  data-aos="fade-right">
                    <div className="content-left  timeline-logo text-right">
                        <img src={Logo1960} className={"img-fluid"}/>
                    </div>
                </div>
                <div className="content-right-container">
                    <div className="content-right" data-aos="fade-left">
                        <div
                            className="article-number">1960</div>

                        <p><span className="d-block">Se abren oficinas en el puerto </span>
                            <span className="d-block">de San Ysidro, en la garita conocida  </span>
                            <span className="d-block">actualmente como El Chaparral. </span>
                        </p>
                    </div>
                </div>
                <div className="meta-date"/>
            </div>
            <div className="timeline-article">
                <div className="content-left-container">
                    <div className="content-left" data-aos="fade-right">
                        <div
                            className="article-number">1985</div>
                        <p><span className="d-block">Se instalan oficinas en Estados </span>
                            Unidos para entrega de documentos.</p>

                        <p><span className="d-block">En 1991 nace R&R International </span>
                            <span className="d-block">Freight Forwarding para atender las </span>
                            <span className="d-block">necesidades de servicios de </span>
                            <span className="d-block">almacenamiento, consolidación y </span>
                            reexpedición de mercancías.</p>
                    </div>
                </div>
                <div className="content-right-container" data-aos="fade-left">
                    <div className="content-right timeline-logo text-left">
                        <img src={Logo1985} className={"img-fluid"}/>
                    </div>
                </div>
                <div className="meta-date"/>
            </div>
            <div className="timeline-article">
                <div className="content-left-container" data-aos="fade-right">
                    <div className="content-left  timeline-logo text-right">
                        <img src={Logo1994} className={"img-fluid"}/>
                    </div>
                </div>
                <div className="content-right-container">
                    <div className="content-right" data-aos="fade-left">
                        <div
                            className="article-number">1994</div>

                        <p><span className="d-block">Con el desplazamiento de la Aduana </span>
                            <span className="d-block">a la Mesa de Otay se consolida la </span>
                            <span className="d-block">nueva ubicación de nuestras oficinas </span>
                            Garita de Otay.</p>
                        <p><span className="d-block">En 1999 nace R&R International</span>
                            <span className="d-block">Transport para atender las</span>
                            <span className="d-block">necesidades de transporte con</span>
                            <span className="d-block">cobertura en las principales</span>
                            aduanas de la región.</p>
                    </div>
                </div>
                <div className="meta-date"/>
            </div>
            <div className="timeline-article">
                <div className="content-left-container">
                    <div className="content-left" data-aos="fade-right">
                        <div
                            className="article-number">2002</div>

                        <p>
                            <span className={"d-block"}>Agencia Aduanal Rodriguez se posiciona como uno de los proveedores más confiables en la región, realizando </span> inversiones importantes en tecnología aplicada al comercio exterior para hacer frente a las actualizaciones y modernización del sistema electrónico aduanero.
                        </p>
                    </div>
                </div>
                <div className="content-right-container" data-aos="fade-left">
                    <div className="content-right  timeline-logo text-left">
                        <img src={Logo2002} className={"img-fluid"}/>
                    </div>
                </div>
                <div className="meta-date"/>
            </div>
            <div className="timeline-article">
                <div className="content-left-container">
                    <div className="content-left  timeline-logo text-right" data-aos="fade-right">
                        <img src={Logo2020} className={"img-fluid"}/>
                    </div>
                </div>
                <div className="content-right-container">
                    <div className="content-right" data-aos="fade-left">
                        <div
                            className="article-number">2020</div>

                        <p><span className="d-block">Después de casi 20 años, se reinventa </span>
                            <span className="d-block">nuestra identidad corporativa, conformada </span>
                            por la operación de las tres unidades de negocio.</p>
                    </div>
                </div>
                <div className="meta-date"/>
            </div>
        </div>
    </section>);
}

export default Timeline;