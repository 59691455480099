import {Container, Row, Col} from "react-bootstrap";
import React,{Component} from "react";
const Fortalezas = () => {
    return <Container className={"py-4 py-md-5 freight-fortalezas"}>
        <Row>
            <Col md={10} className={"mx-auto"}>
                <h2 className={"mb-1 mb-md-4"}>Nuestras fortalezas:</h2>

                <ul className={"check-mark-list"}>
                    <li><span>Análisis constante de las operaciones en desarrollo y futuras.</span></li>
                    <li><span>Escalación de prioridades.</span></li>
                    <li><span>Visión de la relación negocio-cliente.</span></li>
                    <li><span>Tecnología integrada y adaptable.</span></li>
                    <li><span>Visión global en la cadena de suministro para impulsar nuevas estrategias de atención y servicio.</span></li>
                </ul>
            </Col>
        </Row>
    </Container>
}

export default Fortalezas;
