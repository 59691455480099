import MainHeader from "../../UI/MainHeader";
import MainImage from "../../../images/freight/freight-bg.png";
import CoreValues from "../nosotros/CoreValues";
import SolicitaCotizacion from "./SolicitaCotizacion";
import PluginChat from "../../UI/PluginChat";
import Footer from "../../UI/Footer";
import Fortalezas from "./Fortalezas";
import "./FreightForwarding.scss";
import InfoAdicional from "./InfoAdicional";
import Soluciones from "./Soluciones";
import QuienesSomos from "./QuienesSomos";
import React,{Component} from "react";
const FreightForwarding = () => {
    return <div className={"freight-forwarding"}>
        <MainHeader type={"freight"} image={MainImage} activeButton={true} buttonSettings={{
            'text': 'Solicita asesoría',
            'url': '/freight-forwarding/cotizacion'
        }} header={<h4 className={"mt-2"}>
            <span className={"d-block"}>R&R International </span>
            <span className={"d-block"}>Freight Forwarding</span>
        </h4>}/>
        <QuienesSomos/>
        <Soluciones/>
        <Fortalezas/>
        <CoreValues className={"freight-forwarding"}/>
        <InfoAdicional/>
        <SolicitaCotizacion/>
        <PluginChat/>
        <Footer/>
    </div>
}

export default FreightForwarding;
