import {useRef, useState,  useEffect}  from "react";
import {IsViewportVisible} from "./../../../hooks/IsViewportVisible";
import tusSociosMobile from "../../../images/inicio/tus-socios-mobile.png";
import React,{Component} from "react";
const TusSocios = ({isMobile}) => {
    const [scroll, setScroll] = useState();
    const sociosRef = useRef();

    useEffect(() => {
        window.addEventListener("scroll", () => {
            setScroll(IsViewportVisible(sociosRef.current.getBoundingClientRect()));
        });
    }, []);

    return (<div>
        <div className={`tus-socios ${scroll ? 'scroll' : ''}`} ref={sociosRef}>
            {isMobile && <img src={tusSociosMobile} className={`w-100 my-5 tus-socios-img ${scroll ? 'scroll' : ''}`}/> }
            {!isMobile && <h2>Tus socios en comercio exterior</h2>}
        </div>
    </div>);
}

export default  TusSocios;