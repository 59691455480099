import {UseViewPort} from "./../../hooks/useViewPort";
import "./PluginChat.scss";
import React,{Component} from "react";
const PluginChat = () => {
    const isMobile = UseViewPort() < 767;

    return <div>
        {isMobile && <a className={"wa-mobile"} target="_blank" href={"https://api.whatsapp.com/send?phone=526644102854"}><span/></a>}
        {!isMobile && <div className="elfsight-app-95387155-cae9-4dfa-af31-dc63cc18b202"/>}
    </div>;
}

export default PluginChat;